import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./index.css"

import CTA from "../images/cta.png"
import Logo from "../images/logo.png"

const IndexPage = () => (
  <Layout>
    <SEO title="THE CALL EXPERIMENT – EMPOWHER NY" />
    <div className="call-container">
    	<div className="call-video">
            <iframe src="https://www.youtube.com/embed/H6HIPFeueAA" frameborder="0" allow="fullscreen; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    	</div>
    	<a id="take-action" className="call-cta" target="_blank" href="http://empowherny.org/thecall">
    		<div className="call-cta-hover"></div>
    		<img src={CTA} alt=""></img>
    	</a>
    	<img className="call-logo" src={Logo} alt=""></img>
    </div>
  </Layout>
)

export default IndexPage
